<template lang="pug">
div(class="forum__select" ref="forum__select" @click="getForums")
    //div {{selectedForum}}
    z-select(placeholder="Выберите форум"
              :items="forums"
              v-model="selectedForum"
              :titleFunction="titleFunction"
              :bordered="false"
              :show-icon="false"
              @update:modelValue="selectedForumInput"
              class="forum__select__select"
    )
        template(v-slot:item="{item}")
            forum-select-item(:item="item" @favoriteClick="favoriteClick(item)")
        template(v-slot:footer="{item}")
            div(class="forum__select__select__tags")
                button(v-for="(tag, tagIndex) in tags" :key="`tag_${tagIndex}`"
                        @click="tagSelect(tag.tag)"
                        :class="{'forum__select__select__tags_tag':true, 'forum__select__select__tags_active':tag.tag === currentTag}"
                ) {{tag.name}}
                div(class="forum__select__dropdown_inner__tags__flex")
</template>

<script setup>
import { defineProps, defineEmits, ref, shallowRef, computed, watch } from 'vue'
import axios from 'axios'
import io from '../../plugins/socketio'
import ZSelect from "../../components/z-select";
import ForumSelectItem from "./Forum-select-item";

const props = defineProps({
    currentForum: { default: null }
})

const emit = defineEmits(['forum_selected'])

const tags = shallowRef([
    {name:'Игра', tag:'game'},
    {name:'Архибаш', tag:'bash'},
    {name:'Форум', tag:'forum'},
    {name:'Все', tag:''},
])

const forums = ref([])
const isSelectOpen = ref(false)
const selectedItemIndex = ref(0)
const searchString = ref('')
const currentTag = ref('game')
const isLoading = ref(false)
const isEditForum = ref(false)
const selectedForum = ref(props.currentForum)

const filteredItems = computed(() => {
    if (!searchString.value){
        return forums.value
    } else {
        return this.forums.value.filter(item =>
            item.name.toUpperCase().includes(searchString.value.toUpperCase()) || item.id.toString().includes(searchString.value))
    }
})

getForums()
async function getForums(){
    // console.log('get')
    isLoading.value = true
    let getParams = { params: { tag: currentTag.value } }
    axios.get('get_forums', getParams)
        .then(response => {
            isLoading.value = false
            forums.value = response.data
        })
        .catch(() => {
            isLoading.value = false
        })
}

function titleFunction (value) {
    return (value && 'id' in value) ? `#${value.id} ${value.name}` : ''
}

function selectedForumInput () {
    emit('forum_selected', selectedForum.value)
}

function tagSelect (tag) {
    currentTag.value = tag
    selectedItemIndex.value = 0
    getForums()
}

function favoriteClick (item) {
    item.is_favorite = !item.is_favorite
    io.emit('forum_set_forum_settings', item, response => {
        getForums()
    })
}

watch(
    () => props.currentForum,
    (newValue) => {
        selectedForum.value = newValue
        // console.log(newValue)
    }
)

io.on('forum_post', (post)=>{
    let forum = forums.value.find( item => item.id === post.forum_id)
    if (forum){
        forum.poster = {...post.user}
    }
})

</script>

<style scoped>
    .forum__select{
        width: calc(100% - 5rem);
        display: flex;
        align-items: center;
        position: relative;
    }

    .forum__select{
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
    }

    .forum__select ::v-deep(.z-select){
        margin-bottom: 0;
    }

    .forum__select__save{
        height: 100%;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .forum__select ::v-deep(.z-select input),
    .forum__select ::v-deep(.z-select)
    {
        cursor: pointer;
    }

    .forum__select ::v-deep( .z-select input){
        padding-left: 0;
    }

    .forum__select ::v-deep(.z-select-focused input),
    .forum__select ::v-deep(.z-select-focused)
    {
        cursor: text;
    }

    .forum__select ::v-deep(.z-select__input__container){
        padding-left: var(--indent-size);
    }

    .forum__select ::v-deep(.z-select__input__container:hover){
        background: var(--transparent-hover-background);
        filter: var(--transparent-hover-filter);
    }

    .forum__select ::v-deep(.z-select-focused .z-select__input__container:hover){
        background: none;
        filter: none;
    }


    .forum__select ::v-deep(.z-select) {
        margin-bottom: 0;
    }

    .forum__select ::v-deep(.z-select__dropdown){
        width: calc(100% + 2.5rem - 1px);
        /*margin-right: 2px;*/
        /*margin-left: 2px;*/
    }

    .forum__select ::v-deep(.z-select__dropdown__list) {
        /*height: 15rem;*/
        /*max-height: calc(var(--vh, 1vh) * 100 - 8rem);*/
        --forum__select__dropdown_inner-height: calc(var(--block-height-medium)*7);
        max-height: calc(var(--vh100) - 6.5rem);
        height: var(--forum__select__dropdown_inner-height);
    }


    .forum__select ::v-deep(v-dropdown-menu__body){
        transform: translateX(-1px);
    }

    .forum__select ::v-deep(ul) {
        margin: 0;
        padding: 0;
        background: var(--panel__header__menu_background);
        color: var(--panel__header__menu_color);
        position: relative;
        /*transform: translateX(-1px);*/
    }
    .forum__select ::v-deep(.z-select__dropdown__list__item) {
        padding-right: 0.3rem;
    }

    .forum__select__select__tags{
        height: var(--block-height-medium);
        line-height: var(--block-height-medium);
        display: flex;
        border-top: var(--border-color-alter) 1px solid;
    }

    .forum__select__select__tags__flex{
        flex: 1;
    }

    .forum__select__select__tags__add{
        max-width: 3rem;
    }

    .forum__select__select__tags_tag{
        height: 100%;
        padding: 0 var(--indent-size);
        padding-bottom: var(--tab-border-height);
    }

    .forum__select__select__tags_tag:hover{
        background: var(--transparent-hover-background);
        filter: var(--transparent-hover-filter);
    }


    .forum__select__select__tags_active{
        border-bottom: var(--active-tab-border-color) var(--tab-border-height) solid;
    }
</style>
