<template lang="pug">
div(class="forum-post-redis" v-show="!isBlank")
    div(class="forum-post-redis__header")
        div(class="forum-post__header__user" v-html="userName")
    div(v-html="post.text" :class="[post.text_color]" class="forum-post-redis__text")
</template>

<script setup>
import {defineProps, ref, computed} from 'vue'
import axios from 'axios'

const props = defineProps({post:{}, user_id:{}})
const userName = ref('')
const isBlank = computed(()=> !props.post.text || props.post.text==='<p></p>')

axios.get('/get_user_by_id', {params:{user_id: props.user_id}}).then( response =>{
    userName.value = response.data.name
})
</script>

<style>
    .forum-post-redis{
        margin-right: var(--indent-size);
        padding-left: var(--indent-size-small);
        border-left: var(--forum-post-redis_border);
        margin-top: var(--indent-size);
    }

    .forum-post-redis__header{
        font-size: var(--font-size-small);
        display: flex;
        justify-content: space-between;
        margin-bottom: var(--indent-size);
        min-height: 1rem;
        max-height: 1rem;
    }

    .forum-post-redis__text{
        font-style: italic;
    }

    .forum-post-redis__text p {
        margin-top: 0;
        margin-bottom: var(--indent-size);
        text-align: justify;
        white-space: normal;
        word-break: break-word;
    }

    .forum-post-redis__text p:nth-last-child(1){
        margin-bottom: 0;
    }

    .forum-post-redis__text p:blank{
        display: none;
    }
</style>
