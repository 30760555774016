<template lang="pug">
div(class="forum-archive__root")
    div(class="forum-archive__header")
        forum-select(:currentForum="currentForum" @forum_selected="forumSelected")
        z-btn(class="z-btn forum-archive__header_btn"
               icon="refresh"
               :transparent="true"
               :loading="isPostsLoading && isLoadingStarted"
               @click="openForumById(currentForum.id)")
    div(class="forum-archive__body" ref="refPosts")
        template(v-for="(post, postIndex) in posts" :key="`template_post_${postIndex}`")
            forum-post(:post="post"
                        :is_editing="post.id === editingId"
                        :is_remote_editing="post.is_editing"
                        @startEditing="onStartEditing(post)"
                        @stopEditing="onStopEditing"
                        :forum_uuid="forumUuid")
            a(v-if="isSearch" :key="`link_${postIndex}`" class="forum-archive__body__goto" @click="goToPost(post.id)") перейти
    div(class="forum-archive__footer")
        z-paginate(:reverse="false" v-model="currentPage" :pages="pages" @change="paginateChange" class="forum-archive__footer__paginate")
        div(class="forum-archive__footer__search")
            z-input(placeholder="Поиск для бога поиска" v-model="search" :dense="true" @change="openSearch")
            z-btn(icon="magnify" :transparent="true" class="forum-archive__footer__search__btn" @click="openSearch")
</template>

<script setup>
import { defineProps, defineEmits, ref, nextTick } from 'vue'
import {v4 as uuidv4} from 'uuid'
import axios from 'axios'
import io from '../../plugins/socketio'
import ForumSelect from "./Forum-select"
import ForumPost from "./Forum-post"
import ZPaginate from "../../components/z-panginate"
import { emitter } from '../../plugins/emitter'
import {
    forumIdOnCreate, onForumEditDelete, onForumEditFinish, onForumEditStart, onForumPostDiff,
    onForumSetForumSettings,
    startEditing,
    stopEditing
} from './forumFuncs'

const count = 25;

const props = defineProps({
    panel: {}
})

const emit = defineEmits(['append_to_route'])

const forumUuid = uuidv4()
const currentForum = ref({})
const posts = ref([])
const refPosts = ref(null)
const isPostsLoading = ref(false)
const isLoadingStarted = ref(false)
const search = ref('')
const currentPage = ref(1)
const pages = ref(0)
const isSearch = ref(false)
const editingId = ref(null)

function created () {
    if ('page' in props.panel) {
        currentPage.value = props.panel.page
    }
    if ('search' in props.panel) {
        search.value = props.panel.search
    }
    forumIdOnCreate(props.panel, openForumById)
}
created()

emitter.on('forum_set_forum_settings', data=>onForumSetForumSettings(data, currentForum))

function openForumById (forumId, page = 0, postId = null) {
    if (!forumId) {
        return
    }
    isLoadingStarted.value = true
    isPostsLoading.value = false

    setTimeout(() => {
        isPostsLoading.value = true
    }, 500)

    let getParams = {
        forum_id: forumId,
        page: (page) ? page : currentPage.value,
        count: count,
        search: search.value
    }
    // debugger
    if (postId) {
        getParams['post_id'] = postId
    }

    io.emit('join_forum_room', { forum_id: forumId })

    axios.get('get_forum_archive', { params: getParams }).then(response => {
        currentForum.value = response.data.forum
        currentPage.value = response.data.page
        pages.value = response.data.pages

        posts.value = response.data.posts
        isLoadingStarted.value = false
        isPostsLoading.value = false
        isSearch.value = !!search.value

        scrollPosts(postId)

        localStorage.setItem('last_forum_id', forumId)
        emit('append_to_route', {
            forum_id: forumId,
            page: currentPage.value,
            search: search.value
        })
    })
}

function scrollPosts (postId = null) {
    nextTick(() => {
        if (postId) {
            refPosts.value.querySelector(`div[data-id="${postId}"]`).scrollIntoView(true)
        } else {
            refPosts.value.scrollTop = 0
        }
    })
}

function forumSelected (forum) {
    currentForum.value = forum
    emit('append_to_route', { forum_id: forum.id })
    openForumById(forum.id)
}

function paginateChange () {
    openForumById(currentForum.value.id)
}

function openSearch () {
    currentPage.value = 1
    paginateChange()
}

function goToPost (postId) {
    search.value = ''
    openForumById(currentForum.value.id, 0, postId)
}

function onStartEditing(post){
    startEditing(post, editingId)
}

function onStopEditing(){
    stopEditing(editingId)
}

io.on('forum_post_edit_start', data=>onForumEditStart(data, posts))
io.on('forum_post_edit_finish', data=>onForumEditFinish(data, posts))
io.on('forum_post_edit_delete', data=>onForumEditDelete(data, posts))
io.on('forum_post_diff', data=>onForumPostDiff(data, posts, refPosts))

</script>

<style>
    .forum-archive__root {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .forum-archive__header {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        width: 100%;
        flex: 0;
        display: inline-flex;
        overflow-y: visible;
        overflow-x: visible;
    }

    .forum-archive__header_btn
    {
        max-width: 2.5rem;
    }

    .forum-archive__body {
        flex: 1;
        overflow-y: auto;
        margin: var(--indent-size);
        /*overflow: visible;*/
    }

    /*.forum-archive__body__edit{*/
    /*    min-height: var(--block-height-big);*/
    /*    background: var(--editor-background) !important;*/
    /*    width: 100%;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    position: relative;*/
    /*    overflow-y: auto;*/
    /*}*/


    .forum-archive__body .forum-post-toolbar{
        position: relative;
        top: -1rem;
    }


    /*.forum-archive__body__edit__container{*/
    /*    display: flex;*/
    /*    margin: var(--indent-size);*/
    /*    overflow-y: auto;*/
    /*    flex: 1;*/
    /*}*/

    .forum-archive__body__edit p{
        margin-bottom: var(--indent-size);
        white-space: normal;
        word-break: break-word;
    }

    .forum-archive__body__edit p:nth-last-child(1){
        margin-bottom: 0;
    }

    .forum-archive__body__edit__container__editor .ProseMirror{
        width: 100%;
        height: 100%;
    }

    .forum-archive__footer {
        max-height: var(--block-height-big);
        display: flex;
        overflow-y: visible;
    }

    .forum-post__text p{
        margin-bottom: var(--indent-size);
        margin-top: 0;
        text-align: justify;
        min-height: 1rem;
    }

    .forum-archive__footer__paginate{
        flex: 1;
    }

    .forum-archive__footer__search{
        margin-left: var(--indent-size);
        margin-right: var(--indent-size);
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .forum-archive__footer__search__btn{
        max-width: 2.5rem;
    }

    .forum-archive__body__goto{
        color: var(--link-color);
        cursor: pointer;
        text-decoration: underline;
        user-select: none;
        margin-left: var(--indent-size);
    }
</style>
