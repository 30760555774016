import { nextTick } from 'vue'
import io from '../../plugins/socketio'
import { emitter } from '../../plugins/emitter'
import {patchApply} from "../../plugins/diff-match-patch";



export function forumIdOnCreate (panel, openForumById) {
    // debugger
    let forumId = null
    if ('forum_id' in panel) {
        forumId = panel.forum_id
    } else {
        forumId = localStorage.getItem('last_forum_id')
    }
    if (forumId) {
        openForumById(forumId)
    }
}

export function startEditing (post, editingId) {
    if (post.id !== editingId.value) {
        editingId.value = post.id
    }
}

export function stopEditing (editingId) {
    editingId.value = null
}

export function scrollToPost (postId, refPosts) {
    nextTick(() => {
        if (postId) {
            refPosts.value.$el.querySelector(`div[data-id="${postId}"]`).scrollIntoView(true)
        } else {
            refPosts.value.scrollTop = 0
        }
    })
}

export function scrollPosts (refPosts) {
    nextTick(() => {
        if (refPosts.value) {

            refPosts.value.$el.scrollTop = refPosts.value.$el.scrollHeight
        }
    })
}

export function scrollPostsIfScrolled (refPosts) {
    let dlg = refPosts.value
    if (dlg) {
        dlg = dlg.$el
        let is_scrolled = (dlg.scrollHeight - dlg.scrollTop) <= (dlg.clientHeight + 10)
        if (is_scrolled) {
            scrollPosts(refPosts)
        }
    }
}

export function saveForumSettings (currentForum) {
    emitter.emit('forum_set_forum_settings', currentForum.value)
    io.emit('forum_set_forum_settings', currentForum.value, response => {
        if (currentForum.value) {
            currentForum.value.is_favorite = response.is_favorite
            currentForum.value.is_commenting = response.is_commenting
            currentForum.value.text_color = response.text_color
        }
    })
}

export function onForumSetForumSettings(data, currentForum){
    if (data.id === currentForum.value.id) {
        Object.assign(currentForum.value, data)
    }
}

export function findPostIndex (posts, data, dataIdField = 'id') {
    return posts.value.findIndex(findPost => (findPost.id === data[dataIdField] && findPost.forum_id === data.forum_id))
}

export function findPostIndexByUUID (posts, data) {
    return posts.value.findIndex(findPost =>  (findPost.uuid === data.uuid && findPost.forum_id === data.forum_id))
}

export function onForumEditStart (data, posts, forumUuid) {
    if (data.forumUuid !== forumUuid) {
        let postIndex = findPostIndexByUUID(posts, data)
        if (postIndex !== -1) {
            posts.value[postIndex]['is_editing'] = true
            // this.posts = Array.from(this.posts)
        }
    }
}

export function onForumEditFinish (data, posts) {
    let postIndex = findPostIndexByUUID(posts, data)
    if (postIndex !== -1) {
        Object.assign(posts.value[postIndex], data)
        delete posts.value[postIndex]['is_editing']
        // this.posts = Array.from(this.posts)
    }
}

export function onForumEditDelete (data, posts) {
    let postIndex = findPostIndexByUUID(posts, data)
    if (postIndex !== -1) {
        posts.value.splice(postIndex, 1)
    }
}

export function onForumPostDiff (data, posts, refPosts) {
    let postIndex = findPostIndexByUUID(posts, data)
    if (postIndex !== -1) {
        let post = posts.value[postIndex]
        post.text_color = data.text_color
        post.text = patchApply(data.diffs, post.text)
        scrollPostsIfScrolled(refPosts)
    }
}
