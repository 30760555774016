<template lang="pug">
div(class="forum__root")
    div(class="forum__header" @mousedown="editorMethods.preventBlur")
        forum-select(:currentForum="currentForum" @forum_selected="forumSelected")
        z-dropdown-menu(:overlay="false" transition="zoom" :withDropdownCloser="true" :direction="'right'")
            template(v-slot:trigger)
                z-btn(icon="dots-vertical"
                       :transparent="true"
                       :disable="!isCurrentForum"
                       :loading="isPostsLoading"
                       :loading_timeout="500"
                       class="forum__header_btn"
                )
            template(v-slot:body)
                ul(class="forum__header__menu_ul " dropdown-closer)
                    li(class="z-list-item forum__header__menu_li panel__header__menu_li-right" @click="hideShowEditor")
                        div(class="forum__header__menu_li-text") {{textShowEditor}}
                    li(class="z-divider")
                    li(class="z-list-item forum__header__menu_li panel__header__menu_li-right" @click="createNewForum")
                        div(class="forum__header__menu_li-text") Создать новый форум
                    li(class="z-list-item forum__header__menu_li panel__header__menu_li-right" @click="editForum")
                        div(class="forum__header__menu_li-text") Редактировать
                    li(class="z-list-item forum__header__menu_li panel__header__menu_li-right" @click="deleteForum")
                        div(class="forum__header__menu_li-text") Удалить
        forum-edit(v-if="isEditForum" :uuid="forumUuid" :forum="currentForum" :is_new="isEditNew" @cancelEdit="cancelEdit" @forumSaved="forumSaved")
        z-confirm(v-if="isDelete" message="Удалить форум?" @yes="deleteForumYes" @no="deleteForumNo")
    div(class="forum__body" @mousedown="editorMethods.preventBlur")
        splitpanes(horizontal :dbl-click-splitter=" false")
            pane(class="forum__body__posts" :size="70" ref="refPosts")
                z-btn(:transparent="true" class="chat__body__posts_next" @click="loadNextPosts" :loading="isLoadingNextPosts") Загрузить ещё
                forum-post(v-for="(post, postIndex) in posts"
                            :key="`post_${postIndex}`"
                            :post="post"
                            :is_editing="post.id === editingId"
                            :is_remote_editing = "post.is_editing"
                            @startEditing="onStartEditing(post)"
                            @stopEditing="onStopEditing"
                            :forum_uuid="forumUuid"
                )
                forum-post(v-for="(post, postIndex) in postsWait" :key="`post_wait_${post.id}`" :post="post" :is_wait="true")
                forum-post-redis(v-for="(post, postIndex) in postsRedis" :key="`post_redis_${postIndex}`" :post="post" :user_id="post.user_id")
                //div(v-for="(post, postIndex) in postsRedis") {{postIndex}} {{post}}
            pane(:class="forumBodyEditClasses" :size="30" v-if="showEditor")
                div(class="forum__body__edit__container" @keydown.ctrl.*="switchCommenting" @keydown.enter.ctrl.prevent.stop="sendPost($event)")
                    z-editor(class="forum__body__edit__container__editor"
                        v-model="postText"
                        :key="forumKey"
                        rich menu
                        :placeholder="editorPlaceholder"
                        :preventEnter="false"
                        @ready="editorReady"
                        @change="forumTextChange"
                    )
    div(v-show="showEditor" :class="forumFooterClasses" @mousedown="editorMethods.preventBlur")
        z-btn(class="z-btn forum__footer__btn" :icon="commentingIcon" :transparent="true" @click="switchCommenting")
        z-color-select(v-model="currentForum.text_color" @change="postColorChange")
        div(class="forum__footer__spacer")
        z-btn(class="z-btn forum__footer__btn" icon="send" :transparent="true" @click="sendPost")
        //div {{forumKey}}
</template>


<script setup>
import { ref, computed, defineProps, defineEmits, nextTick, watch } from 'vue'
import {v4 as uuidv4} from 'uuid'
import axios from 'axios'
import io from '../../plugins/socketio'
import { notifyErrors, notifySuccess } from '../../plugins/notify'
import { dmp, patchApply } from '../../plugins/diff-match-patch'
import {Splitpanes, Pane} from 'splitpanes'
import ForumSelect from "./Forum-select"
import ForumPost from "./Forum-post"
import ForumPostRedis from "./Forum-post-redis"
import ZDropdownMenu from '../../components/z-dropdown-menu'
import ZEditor from '../../components/z-editor'
import ZConfirm from '../../components/z-confirm'
import ZColorSelect from "../../components/z-color-select"
import ForumEdit from "./Forum-edit";
import {
    forumIdOnCreate,
    startEditing,
    stopEditing,
    scrollToPost,
    scrollPosts,
    scrollPostsIfScrolled,
    saveForumSettings,
    findPostIndex,
    onForumSetForumSettings,
    onForumPostDiff,
    onForumEditDelete,
    onForumEditStart,
    onForumEditFinish
} from './forumFuncs'

import {emitter} from '../../plugins/emitter'

import { DB } from '../../plugins/goDB'
const db_forum_posts = DB.table('forum_posts');

import { useAuth } from '../../store/auth'
const { userData } = useAuth()

const props = defineProps({panel:{}})
const emit = defineEmits(['append_to_route', 'remove_from_route'])

const currentForum = ref({})
const isCurrentForum = computed(() => !!currentForum.value && !!currentForum.value.id)
const forumKey = ref(0)
const forumUuid = ref(uuidv4())
const showEditor = ref( !('editor' in props.panel) || props.panel.editor)
const isCommenting = ref( false)

const isPostsLoading = ref(false)
const isLoadingNextPosts = ref(false)

const posts = ref([])
const postsRedis = ref([])
const postsWait = ref([])
const editingId = ref(null)
const refPosts = ref(null)

const refEditor = ref(null)
const editorMethods = {}
const postText = ref('')
let prevPostText = ''


function editorReady({ editor, preventBlur } ){
    refEditor.value = editor
    editorMethods.preventBlur = preventBlur
}


function forumSelected(forum) {
    currentForum.value = forum
    emit('append_to_route', { forum_id: forum.id })
    openForumById(forum.id)
}

emitter.on('forum_set_forum_settings', data=>onForumSetForumSettings(data, currentForum))
emitter.on('resize', ()=>scrollPostsIfScrolled(refPosts))

forumIdOnCreate(props.panel, openForumById)

function onConnect () {
    if (currentForum.value) {
        openForumById(currentForum.value.id, false)
    }
}

io.on('connect', onConnect)
io.on('reconnect', onConnect)
io.on('error', onConnect)

watch(
    () => props.panel.forum_id,
    (value) => {
        // forumIdOnCreate(props.panel, openForumById)
        console.log(`props.panel.forum_id ${props.panel.forum_id}`)
        openForumById(value, true)
    }
)

function openForumById (forumId, isForced) {
    if (!forumId) {
        isPostsLoading.value = false
        return
    }
    isPostsLoading.value = true
    io.emit('get_forum', { forum_id: forumId, uuid: forumUuid.value}, response => {
        if (response && 'forum' in response) {
            setPosts(response.posts, response.redis, isForced)
            currentForum.value = response.forum
            forumKey.value = forumId
            // this.readWaitPosts(forumId)
            isPostsLoading.value = false
            localStorage.setItem('last_forum_id', forumId)
            emit('append_to_route', { forum_id: forumId })
        }
    })
    readWaitPosts(forumId)
}

function setPosts (postsList, redisList, isForced=false) {
    posts.value = postsList
    setPostsRedis(redisList, isForced)
    scrollPosts(refPosts)
}


function setPostsRedis (redis, isForced=false) {
    // debugger
    let user_key = `user_${userData.value.id}`
    if (user_key in redis) {
        if (!refEditor.value || !refEditor.value.isFocused || isForced) {
            prevPostText = redis[user_key].text
            postText.value = prevPostText
            isCommenting.value = redis[user_key].isCommenting
        }
        delete redis[user_key]
    } else {
        prevPostText = ''
        postText.value = ''
        isCommenting.value = false
    }
    postsRedis.value = redis
}

function onStartEditing(post){
    startEditing(post, editingId)
}

function onStopEditing(){
    stopEditing(editingId)
}

io.on('forum_post', (post)=>{
    // debugger
    // db_forum_posts.delete({uuid: post.uuid})
    deleteFromWait(post.uuid)
    let waitPostIndex = postsWait.value.findIndex(findPost => findPost.uuid === post.uuid)
    if (waitPostIndex !== -1) {
        postsWait.value.splice(waitPostIndex, 1) // Убираем из ожидающих подтверждения
    }

    if (currentForum.value && post.forum_id !== currentForum.value.id) {
        return
    } // Это сообщение в другой форум, не тот, что сейчас открыт

    posts.value.push(post)
    scrollPostsIfScrolled(refPosts)
})

async function deleteFromWait(postUuid){
    // console.log(postUuid)
    let findWait = await db_forum_posts.find(item => item.uuid === postUuid)
    if (findWait) {
        await db_forum_posts.delete({ uuid: postUuid })
    }
}

io.on('forum_diff', (data)=>{
    // console.log(refEditor.value)
    if (data.forum_id !== currentForum.value.id || data.forumUuid === forumUuid.value) {
        return
    }

    if (data.user_id === userData.value.id && !(refEditor.value.isFocused && document.hasFocus())) {
        postText.value = patchApply(data.diffs, prevPostText)
        prevPostText = postText.value
        isCommenting.value = data.isCommenting
        currentForum.value.text_color = data.text_color

    } else if (data.user_id !== userData.value.id) {
        let userKey = `user_${data.user_id}`
        let postRedis = {}

        if (userKey in postsRedis.value) {
            postRedis = postsRedis.value[userKey]
        } else {
            postRedis['text'] = ''
            postRedis['user_id'] = data.user_id
            postsRedis.value[userKey] = postRedis
        }

        postRedis.isCommenting = data.isCommenting
        postRedis.text_color = data.text_color
        postRedis.text = patchApply(data.diffs, postRedis.text)

        scrollPostsIfScrolled(refPosts)
    }
})

io.on('forum_post_comment', (data)=>{
    let postIndex = findPostIndex(posts, data, 'master_id')
    if (postIndex !== -1) {
        let post = posts.value[postIndex]
        post.comments.unshift(data)
        scrollPostsIfScrolled(refPosts)
    }
})

io.on('forum_post_comment_edit_finish', (data)=>{
    let postIndex = findPostIndex(posts, data, 'master_id')
    if (postIndex !== -1) {
        let post = posts.value[postIndex]
        let commentIndex = post.comments.findIndex(comment => comment.id === data.id)
        if (commentIndex !== -1) {
            let comment = post.comments[commentIndex]
            comment.text = data.text
        }
    }
})

io.on('forum_post_edit_start', data=>onForumEditStart(data, posts))
io.on('forum_post_edit_finish', data=>onForumEditFinish(data, posts))
io.on('forum_post_edit_delete', data=>onForumEditDelete(data, posts))
io.on('forum_post_diff', data=>onForumPostDiff(data, posts, refPosts))


function sendWaitPosts () {
    postsWait.value.forEach(post => {
        console.log(post)
        post['action'] = 'wait'
        io.emit(post.url, post)
    })
}

async function readWaitPosts (forumId) {
    let waitPosts = await db_forum_posts.findAll(item => item.forum === (forumId + '_' + userData.value.id))
    waitPosts.sort((first, second) => first.time - second.time)
    postsWait.value = waitPosts
    if (postsWait.value.length > 0) {
        sendWaitPosts()
    }
}


const forumFooterClasses = computed(() => [(isCommenting.value) ? 'forum__footer_commenting':'forum__footer_not-commenting', 'forum__footer'])
const forumBodyEditClasses = computed(() => [(isCommenting.value) ? 'forum__body__edit_commenting':'forum__body__edit_not-commenting', 'forum__body__edit'])
const commentingIcon = computed(() => isCommenting.value ? 'comment-processing' : 'comment-processing-outline')
const editorPlaceholder = computed(() => isCommenting.value ? 'Комментарии для бога комментариев' : 'Ответы для бога ответов')
const textShowEditor = computed(() => showEditor.value ? 'Скрыть редактор'  : 'Показать редактор')

function hideShowEditor () {
    showEditor.value = !showEditor.value
    if (showEditor.value) {
        emit('remove_from_route', 'editor')
    } else {
        emit('append_to_route', { editor: showEditor.value })
    }
}

function switchCommenting () {
    isCommenting.value = !isCommenting.value
    forumTextChange(postText.value)
}

function postColorChange () {
    saveForumSettings(currentForum)
}

function forumTextChange (html) {
    let diffs = dmp.patch_make(prevPostText, html)
    prevPostText = html
    io.emit('forum_diff', {
        forum_id: currentForum.value.id,
        diffs: dmp.patch_toText(diffs),
        text_color: (currentForum.value.text_color) ? currentForum.value.text_color : 'black',
        isCommenting: isCommenting.value,
        forumUuid: forumUuid.value
    })
}

function isPostBlank (html) {
    return html === '\n' || html.trim() === '' || html === '<p></p>' || html === '<p><br></p>'
}

function getLastPost () {
    // debugger
    return posts.value[posts.value.length - 1]
}

async function sendPost (event) {
    if (!isPostBlank(postText.value) && !(isCommenting.value && posts.value.length === 0)) {
         let url = (isCommenting.value) ? 'forum_send_comment':'forum_send_text'

         let forumPost = {
             text: postText.value,
             text_color: (currentForum.value.text_color) ? currentForum.value.text_color : 'black',
             uuid: uuidv4(),
             time: Math.floor(Date.now() / 1000),
             user_id: userData.value.id,
             // user: userData.value,
             forum: currentForum.value.id + '_' + userData.value.id,
             forum_id: currentForum.value.id,
             action: 'new',
             url: url,
         }

         if (isCommenting.value){
             forumPost.master_id = getLastPost().id
         } else {
             // console.log(forumPost)
             await db_forum_posts.add(forumPost)
             postsWait.value.push(forumPost)
        }

        io.emit(url, forumPost)
        postText.value = ''
        forumTextChange('')

        scrollPosts(refPosts)
    }
}

function loadNextPosts () {
    if (posts.value.length === 0) {
        return
    }

    let get_posts_params = {
        'last_id': posts.value[0].id,
        'forum_id': currentForum.value.id
    }

    isLoadingNextPosts.value = true
    io.emit('get_posts', get_posts_params, (data) => {
        isLoadingNextPosts.value = false
        posts.value = data.concat(posts.value)
        scrollToPost(data[data.length - 1].id, refPosts)
    })
}

const isEditForum = ref(false)
const isEditNew = ref(false)
const isDelete = ref(false)

function createNewForum () {
    isEditForum.value = true
    isEditNew.value = true
}

function cancelEdit () {
    isEditForum.value = false
    isEditNew.value = false
}

function editForum () {
    isEditForum.value = true
    isEditNew.value = false
}

function forumSaved (forum) {
    isEditForum.value = false
    // this.$emit('forum_selected', forum);
    // this.currentForum = forum;
    openForumById(forum.id)
}


function deleteForum () {
    isDelete.value = true
}

function deleteForumYes () {
    let forumData = { 'id': currentForum.value.id }
    axios.post('/delete_forum/', forumData).then(response => {
        if (response.data.is_valid) {
            isDelete.value = false
            currentForum.value = {}
            posts.value = []
            emit('remove_from_route', 'forum_id')
            notifySuccess(['Форум удален'], 'main')
            openForumById(0)
        }
    }).catch(error => {
        notifyErrors(error, 'main', 'Ошибка удаления форума')
    })
}

function deleteForumNo () {
    isDelete.value = false
}


// window.addEventListener('resize', ()=>{
//     // let dlg = refPosts.value.$el
//     // // console.log('resize', dlg.scrollTop, dlg.scrollHeight, (dlg.scrollHeight - dlg.scrollTop) <= (dlg.clientHeight + 10))
//     // if (dlg.scrollHeight - dlg.scrollTop) <= (dlg.clientHeight + 10){
//     //
//     // }
//     scrollPostsIfScrolled(refPosts)
// })

</script>

<style scoped>
    .forum__root {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .forum__header {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        width: 100%;
        flex: 0;
        display: inline-flex;
        overflow-y: visible;
        overflow-x: visible;
        position: relative;
    }

    .forum__header ::v-deep(.z-dropdown-menu__container){
        transform: translateX(-1px);
        background: var(--panel__header__menu_background);
    }


    .forum__header ul {
        margin: 0;
        padding: 0;
        color: var(--panel__header__menu_color);
        position: relative;
    }

    .forum__body {
        flex: 1;
        overflow-y: auto;
    }

    .forum__body__posts{
        margin: var(--indent-size);
        width: calc(100% - 2*var(--indent-size));
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .forum__body__posts ::v-deep(.forum-post__header){
        display: none;
    }

    .chat__body__posts_next{
        margin-right: var(--indent-size);
        margin-bottom: var(--indent-size-small);
    }

    .forum__body__edit{
        min-height: var(--block-height-big);
        background: var(--editor-background) !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        /*padding-left: var(--indent-size-small);*/
        position: relative;
        overflow-y: auto;
        overflow: visible;
    }

    .forum__body__edit ::v-deep(.z-editor__menu) {
        margin-left: calc(-1*var(--indent-size-small));
    }

    .forum__body__edit__container{
        display: flex;
        margin: var(--indent-size);
        overflow: hidden;
        flex: 1;
    }

    .forum__body__edit__container__editor ::v-deep(.ProseMirror){
        overflow-y: auto;
        height: 10rem;
    }

    .forum__body__edit ::v-deep(p){
        margin-bottom: var(--indent-size);
        /*white-space: normal;*/
        /*word-break: break-word;*/
        min-height: 1rem;
    }


    .forum__body__edit ::v-deep(p:nth-last-child(1)){
        margin-bottom: 0;
    }

    .forum__body__edit-toolbar__container{
        position: relative;
        width: 100%;
        height: 0;
        display: flex;
        justify-content: center;
    }

    .forum__body__edit-toolbar__container__inner {
        position: absolute;
        display: flex;
        transform: translateY(calc(-2.5rem - 1px));
        background: var(--editor-toolbar-background);
        /*display: none;*/
        z-index: -1;
    }

    .forum__body__edit-toolbar__container__inner ::v-deep(button){
        color: var(--editor-toolbar-color);
        max-width: 3rem;

    }

    .is-active {
        z-index: 1;
    }

    .forum__body__edit__container__editor{
        flex: 1;
        margin-left: var(--indent-size-small);
        text-align: justify;
    }

    .forum__body__edit__container__editor ::v-deep(.ProseMirror){
        width: 100%;
        height: 100%;
        padding-right: var(--indent-size);
    }

    .forum-toolbar{
        position: absolute;
        /*background: blue;*/
        top: -2.5rem;
        /*height: 2.5rem !important;*/
        width: 100%;
        margin-left: -4px;
    }

    .forum__footer {
        height: var(--block-height-big);
        display: flex;
        overflow-y: visible;
        background: var(--editor-background);
    }

    .forum__footer__btn,
    .forum__header_btn
    {
        max-width: 2.5rem;
    }

    .forum__footer__spacer{
        flex: 1;
    }

    .forum_header_favorite{
    }

    .forum__footer_commenting,
    .forum__body__edit_commenting
    {
        background: var(--forum_commenting_background) !important;
        color: var(--forum_commenting_color) !important;
    }
</style>
