<template lang="pug">
div(:class="rootClasses" :data-id="post.id" :data-uuid="post.uuid" ref="refPostRoot")
    div(v-if="!is_editing" v-html="post.text" :class="[post.text_color]" class="forum-post__text" @dblclick="startEditing")
    template(v-else)
        z-editor(class="forum-post__edit__editor"
            v-model="editText"
            rich menu fixMenu
            autoFocus
            :preventEnter="false"
            @ready="editorReady"
            @change="postTextChange"
            @keydown.enter.ctrl.prevent="confirmEditing($event)"
        )
            template(v-slot:menu-left)
                div(class="forum-post__edit__editor__color-select")
                    z-color-select(v-model="post.text_color")
            template(v-slot:menu-right)
                z-btn(icon="trash-can" @click="deleteEditing")
        z-confirm(v-show="isConfirming" message="Удалить пост?" @yes="deleteConfirm" @no="deleteCancel")
    forum-post-comments(:comments="post.comments")
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue'
import io from '../../plugins/socketio'
import ZEditor from '../../components/z-editor'
import ZConfirm from '../../components/z-confirm'
import ZColorSelect from "../../components/z-color-select"
import ForumPostComments from './Forum-post-comments'
import { dmp } from '../../plugins/diff-match-patch'

const props = defineProps({
    post: {},
    is_wait: { default: false },
    is_editing: { default: false },
    is_remote_editing: { default: false },
    forum_uuid: {},
})

const emit = defineEmits(['startEditing', 'stopEditing'])

const rootClasses = computed(()=> {
    let classes = ['forum-post']
    if (props.post.text_color) {
        classes.push(props.post.text_color)
    }
    if (props.is_wait) {
        classes.push('forum-post_wait')
    }
    if (props.is_editing) {
        classes.push('forum-post_editing')
    }
    if (props.is_remote_editing) {
        classes.push('forum-post_remote-editing')
    }
    return classes
})

const editText = ref('')
const refEditor = ref(null)
let prevPostText = ''

function editorReady({ editor } ){
    refEditor.value = editor
}

function startEditing () {
    if (!props.is_wait && !props.is_editing) {
        let emit_data = {
            id: props.post.id,
            forumUuid: props.forum_uuid,
            forum_id: props.post.forum_id,
            uuid: props.post.uuid
        }

        io.emit('forum_post_edit_start', emit_data, response => {
            if (response && 'is_valid' in response && response.is_valid) {
                emit('startEditing')
                registerOnClickOutside()
                editText.value = response.post.text
                prevPostText = editText.value
            }
        })
    }
}

function registerOnClickOutside () {
    window.addEventListener('click', clickOutside)
}

function destroyOnClickOutside () {
    window.removeEventListener('click', clickOutside)
}

const isConfirming = ref(false)
const refPostRoot = ref(null)

function clickOutside (event) {
    if (!isConfirming.value && !refPostRoot.value.contains(event.target)) {
        confirmEditing()
    }
}

function confirmEditing (event) {
    let emit_data = { ...props.post }
    emit_data.text = editText.value

    io.emit('forum_post_edit_finish', emit_data, response => {
        if (response && 'is_valid' in response && response.is_valid) {
            destroyOnClickOutside()
            emit('stopEditing')
        }
    })
}

function deleteEditing () {
    isConfirming.value = true
}

function deleteConfirm () {
    let emit_data = {
        id: props.post.id,
        uuid: props.post.uuid,
        forum_id: props.post.forum_id
    }
    io.emit('forum_post_edit_delete', emit_data, response => {
        if (response && 'is_valid' in response && response.is_valid) {
            destroyOnClickOutside()
            emit('stopEditing')
            isConfirming.value = false
        }
    })
}

function deleteCancel () {
    isConfirming.value = false
}

function postTextChange (html) {
    if (refEditor.value.isFocused) {
        let diffs = dmp.patch_make(prevPostText, html)
        prevPostText = html

        io.emit('forum_post_diff', {
            id: props.post.id,
            uuid: props.post.uuid,
            forum_id: props.post.forum_id,
            diffs: dmp.patch_toText(diffs),
            text_color: props.post.text_color,
            forumUuid: props.forum_uuid
        })
    }
}

</script>

<style scoped>
    .forum-post {
        padding-right: var(--indent-size-small);
        margin-right: var(--indent-size-small);
        padding-left: var(--indent-size-small);
        padding-bottom: var(--indent-size-small);
        margin-left: 2px;
        margin-bottom: 2px;
        position: relative;
        white-space: normal;
        word-break: break-word;

    }

    .forum-post_wait {
        filter: grayscale(100%);
    }

    .forum-post_editing {
        background: var(--editor-background);
        /*border: 2px solid #42b983;*/
    }

    .forum-post-toolbar {
        position: absolute;
        top: -2.5rem;
        width: calc(100% - 2px);
        margin-left: 1px;
        display: flex;
    }

    .forum-post-toolbar__inner {
        color: var(--editor-toolbar-color);
        height: 2.5rem;
        display: flex;
        margin: auto;
        justify-content: center;
        background: var(--editor-toolbar-background);
    }

    .forum-post-toolbar__inner ::v-deep(.z-btn) {
        width: 3rem;
    }

    .forum-post-toolbar__color-select ::v-deep(button) {
        padding: 0 !important;
        display: flex;
    }

    .forum-post-toolbar__color-select ::v-deep(button:hover) {
        background: var(--transparent-hover-background);
    }

    .forum-post__header {
        font-size: var(--font-size-small);
        display: flex;
        justify-content: space-between;
        margin: var(--indent-size) 0;
        /*color: transparent;*/
    }

    .forum-post .forum-post__edit__editor ::v-deep(p) {
        margin-top: 0;
        margin-bottom: var(--indent-size);
        text-align: justify;
        /*white-space: normal;*/
        word-break: break-word;
        min-height: 1rem;
    }

    .forum-post__edit__editor ::v-deep(br),
    .forum-post__text ::v-deep(br)
    {
        margin-bottom: var(--indent-size);
        display: block;
        content: "";
    }

    .forum-post__edit__editor ::v-deep(p:nth-last-child(1)),
    .forum-post__text ::v-deep(p:nth-last-child(1)) {
        margin-bottom: 0;
    }

    .forum-post__edit__editor ::v-deep(p:blank) {
        display: none;
    }

    .forum-post_remote-editing{
        outline: var(--forum-post-redis_border);
    }

    .forum-post__text ::v-deep(p){
        margin-bottom: var(--indent-size);
        margin-top: 0;
        text-align: justify;
        min-height: 1rem;
    }

    .forum-post ::v-deep(.z-editor__menu){
        transform: translateY(-2.5rem);
    }

    .forum-post__edit__editor__color-select{
        background: var(--primary-color);
        color: var(--alter-text-color);
        /*color: red;*/
    }

    .forum-post ::v-deep(.z-confirm){
        /*bottom: 100%*/
        transform: translateY(-100%) translateX(-50%);
    }
</style>
