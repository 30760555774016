<template lang="pug">
div(class="forum-edit")
    div(class="forum-edit__body")
        z-input(label="Наименование" autofocus v-model="forumData.name")
        div(class="forum-edit__body__tags")
            z-checkbox(v-model="forumData.tagGame"  label="Игра")
        div(class="forum-edit__body__buttons")
            z-btn(class="forum-edit__body__buttons_button" :disable="!valid" :loading="isSaving" @click="saveForum") Сохранить
            z-btn(class="forum-edit__body__buttons_button" @click="cancelEdit") Отмена
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { notifyErrors, notifySuccess } from '../../plugins/notify'
import axios from 'axios'
import ZCheckbox from '../../components/z-checkbox'

const props = defineProps({
    forum:{},
    is_new:{type:Boolean, default:false}
})

const emit = defineEmits(['cancelEdit', 'forumSaved'])

const isSaving = ref(false)
const forumData = ref({
    id: null,
    name: '',
    tagGame: false,
})

const valid = computed(() => {
    return !!forumData.value.name
})

if (!props.is_new && props.forum) {
    forumData.value = { ...props.forum }
    let findGame = props.forum.tags.findIndex(item => {
        return item.tag === 'game'
    })
    forumData.value.tagGame = (findGame >= 0)
}

if (props.is_new) {
    forumData.value.tagGame = true
}

function cancelEdit () {
    emit('cancelEdit')
}

function saveForum () {
    const forum_data = {
        id: forumData.value.id,
        name: forumData.value.name,
        tags: [{ tag: (forumData.value.tagGame) ? 'game' : 'forum' }]
    }

    isSaving.value = true
    axios.post('/save_forum/', forum_data)
        .then(response => {
            isSaving.value = false
            emit('forumSaved', response.data)
            notifySuccess(['Форум сохранен'], 'main')
        })
        .catch(error => {
            isSaving.value = false
            console.log(error)
            notifyErrors(error, 'main', 'Ошибка сохранения форума')
        })
}

</script>

<style scoped>
    .forum-edit{
        padding: var(--indent-size);
        position: absolute;
        width: 100%;
        z-index: 6;
    }

    .forum-edit__body{
        background: var(--editor-background);
        padding: var(--indent-size);
        box-shadow: var(--droopdown-box-shadow);
        overflow: hidden;
    }

    .forum-edit__body__tags{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: calc(var(--indent-size)*2);
    }

    .forum-edit__body__buttons{
        height: var(--block-height-medium);
        line-height: var(--block-height-medium);
        display: flex;
    }

    .forum-edit__body__buttons_button{
        margin-right: var(--indent-size);
    }

</style>
