<template lang="pug" >
div(:class="{'item':true, 'item-favorite':item.is_favorite}")
    div(class="item__name" v-html="`#${item.id} ${item.name}`")
    div(class="item__poster") {{item.poster.name}}
    div(class="item__favorite" @click.prevent.stop="favoriteClick")
        mdicon(:name="iconName")
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({item:{}})
const emit = defineEmits(['favoriteClick', 'click'])

function favoriteClick () {
    emit('favoriteClick', props.item)
}

function itemClick (event) {
    emit('click', event)
}

const iconName = computed(()=>(props.item.is_favorite) ? 'star' : 'star-outline')

</script>

<style scoped>
    .item{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item__name{
        flex: 1;
    }

    .item__poster{
        flex: 0;
        max-width: 5rem;
        font-size: var(--font-size-small);
        margin-left: var(--indent-size);
        overflow-wrap: break-word;
    }

    .item__favorite{
        flex: 0;
        width: 1.5rem;
        min-width: 1.5rem;
        color: var(--panel__header__menu_background);
        margin-left: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /*background: red;*/
    }

    .item__favorite>svg{
        display: none !important;
    }

    .item__favorite:hover{
        color: var(--favorite-color);
    }

    .item-favorite>.item__favorite>svg,
    .z-select__dropdown__list__item:hover .item__favorite>svg
    {
        display: block !important;
    }


    .item-favorite>.item__favorite{
        color: var(--favorite-color);
    }
</style>
