<template lang="pug">
div(:class="['comment', comment.text_color]" ref="refCommentRoot")
    div(v-html="comment.user.name" class="comment__user")
    div :
    div(v-if="!isEditing" v-html="comment.text" class="comment__text" @dblclick="startEditing")
    div(v-else class="comment__edit" )
        z-editor(class="comment__edit__editor" menu fixMenu rich v-model="comment.text")
</template>

<script setup>
import {defineProps, ref} from 'vue'
import ZEditor from '../../components/z-editor'
import io from '../../plugins/socketio'

const props = defineProps({
    comment: {}
})

const isEditing = ref(false)
const refCommentRoot = ref(null)

function registerOnClickOutside () {
    window.addEventListener('click', clickOutside)
}

function destroyOnClickOutside () {
    window.removeEventListener('click', clickOutside)
}

function clickOutside (event) {
    if (!refCommentRoot.value.contains(event.target)) {
        confirmEditing()
    }
}

function startEditing () {
    if (!isEditing.value) {
        registerOnClickOutside()
        // this.tipTapEditor.setContent(this.comment.text, false)
        isEditing.value = true
    }
}

function confirmEditing (event) {
    let emit_data = { ...props.comment }
    // emit_data.text = this.tipTapEditor.getHTML()
    io.emit('forum_post_comment_edit_finish', emit_data, response => {
        if (response && 'is_valid' in response && response.is_valid) {
            destroyOnClickOutside()
            isEditing.value = false
        }
    })
}
</script>

<style>
    .comment{
        display: flex;
        font-size: var(--font-size-small);
        position: relative;
    }

    .comment p{
        margin: 0;
    }

    .comment__text{
        margin-left: var(--indent-size);
    }

    .comment__user{
        white-space: nowrap;
    }

    .comment-toolbar{
        position: absolute;
        top: -2.5rem;
        /*height: 2.5rem !important;*/
        width: 100%;
        margin-left: 1px;
        z-index: 2;
        display: flex;
        justify-content: center;
    }

    .comment-toolbar__inner {
        color: var(--editor-toolbar-color);
        height: 2.5rem;
        display: flex;
        margin: auto;
        justify-content: center;
        background: var(--editor-toolbar-background);
    }

    .comment-toolbar__inner .z-btn {
        width: 3rem;
    }

    .comment__edit{
        margin-left: var(--indent-size);
        background: var(--editor-background);
        flex: 1;
    }
</style>
